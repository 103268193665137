

















import { Asset } from 'contentful'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    image: {
      type: Object as PropType<Asset>,
      required: true,
    },
    buttonLabel: {
      type: String,
      required: true,
    },
    targetUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    url(): string {
      return this.image.fields.file.url
    },
    queryString(): string {
      return window.location.search || ''
    },
  },
})
