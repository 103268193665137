import { Entry } from 'contentful'
import { GetterTree } from 'vuex/types'
import { Answer, AnswerValue, RootState, Question, Theme } from '@typesCustom/index'
import { AnswerOptionContent, QuestionContent, SolutionContent, SolutionStep } from '@typesCustom/contentful'

const getters: GetterTree<RootState, RootState> = {
  /**
   * Return currently active theme
   */
  active: (state: RootState): Theme | undefined => {
    return state.themes.find(theme => theme.id === state.active)
  },

  /**
   * Return complete set of answers
   */
  answers: (state: RootState): Answer[] => {
    return state.themes.reduce((acc: Answer[], t: Theme): Answer[] => {
      return [...acc, ...t.answers]
    }, [])
  },

  /**
   * Single formatted answer for Google Tag Manager
   */
  gtmAnswer: (state: RootState, { optionText }) => (a: Answer): Answer => {
    const question = state.texts.questions[a.id].question
    const answer = optionText(a.id, a.value)
    return { ...a, question, answer }
  },

  /**
   * Formatted answers for Google Tag Manager
   */
  gtmAnswers: (state: RootState, { answers, gtmAnswer }): Answer[] => {
    return answers.map((a: Answer) => gtmAnswer(a))
  },

  /**
   * Return current set of questions
   */
  questions: (state: RootState, { active }: { active: Theme }): Question[] => {
    return active && active.questions ? active.questions : []
  },

  /**
   * Return disabled state
   */
  disabled: (state: RootState, { answers }: { answers: Answer[] }): boolean => {
    const ids: string[] = answers.reduce((acc: string[], x: Answer) => {
      return x.value !== null ? [...acc, x.id] : acc
    }, [])
    for (const id of state.required) {
      if (!ids.includes(id)) {
        return true
      }
    }
    return false
  },

  /**
   * Return next 'theme' (page)
   */
  next: (state: RootState, { active }: { active: Theme }): string => {
    if (state.themes.length && active) {
      const next = state.themes.find(theme => theme.position === active.position + 1)
      return next ? next.id : 'results'
    }
    return 'home'
  },

  /**
   * Return display text based on question UID
   */
  displayText: (state: RootState) => (uid: string): string => state.texts.questions[uid].question,

  /**
   * Return description based on question UID
   */
  descText: (state: RootState) => (uid: string): string => state.texts.questions[uid].description || '',

  /**
   * Return help text (for tooltip) based on question UID
   */
  helpText: (state: RootState) => (uid: string): string => state.texts.questions[uid].help || '',

  /**
   * Return UI text for given slug
   */
  uiText: (state: RootState) => (slug: string): string => state.texts.ui[slug] || slug,

  /**
   * Return option text based on question UID and given answer value
   */
  optionText: (state: RootState) => (uid: string, value?: AnswerValue): string => {
    const question: QuestionContent = state.texts.questions[uid]
    if (!question.options) {
      return value as string
    }
    const fields = question.options!.map((o: Entry<AnswerOptionContent>) => o.fields)
    if (fields.length > 1) {
      if (value === null) {
        value = 'null'
      }
      const field = fields.find((x: AnswerOptionContent) => x.value === String(value))
      if (field) {
        return field.label
      }
    }
    return fields[0].label
  },

  /**
   * Return the visuals of a questions's options based on the question UID
   */
  optionVisuals: (state: RootState) => (uid: string, active: boolean = false): { [key: string]: string } => {
    const question: QuestionContent = state.texts.questions[uid]
    const fields = question.options!.map((o: Entry<AnswerOptionContent>) => o.fields) || []

    return fields!.reduce((acc: { [key: string]: string }, x: AnswerOptionContent) => {
      if (active && x.value && x.visualActive && x.visualActive.fields) {
        acc[x.value as string] = x.visualActive.fields.file.url
      } else if (x.value && x.visual && x.visual.fields) {
        acc[x.value as string] = x.visual.fields.file.url
      }
      return acc
    }, {})
  },

  /**
   * Conditions to show loading spinner
   */
  loading: (state: RootState, { questions }): boolean =>
    state.uiState === 'loading' ||
    state.uiState === 'fetching' ||
    (!state.cached && !state.solution && typeof state.httpResponse === 'undefined' && !questions.length),

  /**
   * Get map of all the individual solution measures
   */
  solutionTexts: (state: RootState): { [key: string]: SolutionContent } => {
    return state.texts.solutions.reduce((acc: { [key: string]: SolutionContent }, s: SolutionStep) => {
      s.maatregelen.forEach((m: Entry<SolutionContent>) => {
        acc[m.sys.id] = m.fields
      })
      return acc
    }, {})
  },

  /**
   * Get the logos for the open data loading screen: starting with 'logo-'
   */
  logos: (state: RootState): string[] => {
    const { media } = state.texts
    return Object.keys(media).reduce((acc: string[], key: string) => {
      return key.includes('logo-') ? [...acc, media[key]] : acc
    }, [])
  },
}

export default getters
