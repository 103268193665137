export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(Math.round(value))
}

export const formatPercentage = (value: number): string => {
  return new Intl.NumberFormat('nl-NL', { style: 'percent' }).format(value)
}

export const slugify = (text: string): string => {
  const result = text.replace(/([a-z][A-Z])/g, match => match.substr(0, 1) + '-' + match.substr(1, 1).toLowerCase())

  return result
    .toLowerCase()
    .replace(/[^-a-z0-9]+/g, '-')
    .replace(/-{2,}/, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}
