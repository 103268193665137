








import Vue from 'vue'

export default Vue.extend({
  mounted() {
    const modal = document.querySelector('.redirect-backdrop')
    const redirect = document.querySelector('.redirect')

    if (modal) {
      modal.addEventListener('click', event => {
        modal.classList.remove('redirect-show')
      })

      document.querySelectorAll('a').forEach(elem => {
        // tslint:disable-next-line:only-arrow-functions
        elem.addEventListener('click', function(event) {
          if (this.href.includes('redirect')) {
            event.preventDefault()
            const iframe = document.querySelector('iframe')
            if (iframe) {
              iframe.src = this.href
              modal.classList.add('redirect-show')
            }
          }
        })
      })
    }

    if (redirect) {
      redirect.addEventListener('click', event => {
        event.stopPropagation()
      })
    }
  },
})
