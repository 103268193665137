import Vue from 'vue'
import Router from 'vue-router'
import ResultPage from './views/ResultPage.vue'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/results',
      name: 'results',
      component: ResultPage,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (!Object.keys(to.query).length && Object.keys(from.query).length) {
    const name = to.name || undefined
    const query = Object.keys(from.query).reduce((acc: { [key: string]: string }, key: string) => {
      if (key.includes('utm')) {
        acc[key] = from.query[key] as string
      }
      return acc
    }, {})
    Object.keys(query).length ? next({ name, query }) : next()
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    store.commit('SET_ACTIVE', to.name)
  }
})

export default router
