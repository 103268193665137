

























































import { mapGetters } from 'vuex'
import Vue, { PropType } from 'vue'
import { formatCurrency } from '../lib/formatters'
import { HouseScanScale, SolutionOpportunityResult } from '@typesCustom/index'

export default Vue.extend({
  name: 'saving-solution',
  props: {
    solution: {
      type: Object as PropType<SolutionOpportunityResult>,
      required: true,
    },
    selected: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['uiText']),
    id(): string {
      return this.solution.id
    },
    name(): string {
      return this.solution.name
    },
    investment(): HouseScanScale | null {
      const { results } = this.solution
      return results ? results.investment : null
    },
    payback(): HouseScanScale | null {
      const { results } = this.solution
      return results ? results.payback : null
    },
    saving(): HouseScanScale | null {
      const { results } = this.solution
      return results ? results.saving : null
    },
    units(): number | null {
      const { results } = this.solution
      return results ? results.units : null
    },
    disabled(): boolean {
      return !this.solution.results || !this.payback
    },
    notPossibleText(): string {
      return this.solution.huisscanNotPossibleTxt || this.$store.getters.uiText('maatregel-niet-mogelijk')
    },
    investmentRange(): string {
      return this.investment ? `${formatCurrency(this.investment.min)} - ${formatCurrency(this.investment.max)}` : ''
    },
    savingRange(): string {
      return this.saving ? `${formatCurrency(this.saving.min)} - ${formatCurrency(this.saving.max)}` : ''
    },
    advice(): string {
      const { flag } = this.solution
      switch (flag) {
        case 'alreadyApplied':
          return this.$store.getters.uiText('maatregel-al')
        case 'couldBeBetter':
          return this.$store.getters.uiText('kan-beter')
        case 'notPossible':
          return this.$store.getters.uiText('niet-mogelijk')
        case 'notViable':
          return this.$store.getters.uiText('niet-interessant')
        default:
          return ''
      }
    },
  },
  methods: {
    selectSolution(): boolean {
      if (this.disabled) {
        return false
      }
      this.$emit('select', this.id)
      return true
    },
  },
})
