import { ValidationRule } from '@typesCustom/index'

/* eslint-disable no-useless-escape */
const isEmail = {
  message: 'This email adress is invalid',
  rule: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}

const isPhone = {
  message: 'Please enter a valid phone number',
  rule: /[0-9 \-\+\(\)]{6,}/,
}

const isPostalCode = {
  message: 'Please enter a valid postal code',
  rule: /[0-9]{4}[a-z]{2}/i,
}

const isRequired = {
  message: 'This field is required',
  rule: /\S+/,
}

const isPassword = {
  message: 'Your password must be at least 12 characters long',
  rule: /\S{12,}/,
}

const isDate = {
  message: 'Please enter a valid date',
  rule: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$|^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/,
}

const isUrl = {
  message: 'Please enter a valid URL',
  rule: /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/,
}

const isUrlSafe = {
  message: 'Sorry, only letters, numbers, "-"" and "_" are allowed',
  rule: /^[a-zA-Z0-9_-]*$/,
}

const isAlphaNum = {
  message: 'Sorry, only letters and numbers are allowed',
  rule: /^[a-zA-Z0-9]*$/,
}

export const rules: { [key: string]: ValidationRule } = {
  isEmail,
  isPhone,
  isPostalCode,
  isRequired,
  isPassword,
  isDate,
  isUrl,
  isUrlSafe,
  isAlphaNum,
}

export const validate = (value: string, name: string): boolean => {
  return rules[name].rule.test(value)
}
