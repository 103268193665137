import { RootState } from '@typesCustom/index'

const state: RootState = {
  active: '',
  activeTab: '',
  address: { id: 0 },
  answered: [],
  cached: false,
  comparison: {},
  init: false,
  logoUrl: '',
  required: [],
  scrollTop: 0,
  solution: null,
  texts: {
    banners: [],
    flagInfo: {},
    media: {},
    questions: {},
    solutions: [],
    ui: {},
  },
  themes: [],
  uiState: 'resting',
  uuid: '',
  verified: false,
}

export default state
