













































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { CtaBanner, SolutionStep } from '@typesCustom/contentful'
import { HttpResponse, HouseScanResult, HouseScanSolutionResult } from '@typesCustom/index'

import InfoBar from '../components/InfoBar.vue'
import StepCard from '../components/StepCard.vue'
import SendEmail from '../components/SendEmail.vue'
import BannerBlock from '../components/BannerBlock.vue'
import ProgressBar from '../components/ProgressBar.vue'
import TvwSolution from '../components/TvwSolution.vue'
import SendFeedback from '../components/SendFeedback.vue'
import ResultsFooter from '../components/ResultsFooter.vue'
import ResultsButtons from '../components/ResultsButtons.vue'
import RedirectDialog from '../components/RedirectDialog.vue'
import EnergySavingStep from '../components/EnergySavingStep.vue'

export default Vue.extend({
  components: {
    InfoBar,
    StepCard,
    SendEmail,
    BannerBlock,
    ProgressBar,
    TvwSolution,
    SendFeedback,
    ResultsFooter,
    ResultsButtons,
    RedirectDialog,
    'saving-step': EnergySavingStep,
  },
  computed: {
    ...mapGetters(['uiText']),
    steps(): SolutionStep[] {
      return this.$store.state.texts.solutions
    },
    activeStep(): SolutionStep {
      return this.$store.state.texts.solutions.find((s: SolutionStep) => s.slug === this.$store.state.activeTab)
    },
    solution(): HouseScanSolutionResult {
      return this.$store.state.solution
    },
    results(): HouseScanResult[] {
      return this.solution.results
    },
    error(): HttpResponse | undefined {
      return this.$store.state.httpResponse
    },
    address(): string {
      const { street, houseNumber, addition, letter } = this.$store.state.address
      return `${street} ${houseNumber}${addition}${letter}`
    },
    /* logo(): string {
      return this.$store.state.texts.media['company-logo'] || ''
    }, */
    ctaBanners(): CtaBanner[] {
      return this.$store.state.texts.banners
    },
    alt(): string {
      return `Logo ${process.env.VUE_APP_FULL_NAME}`
    },
    appName(): string {
      return process.env.VUE_APP_NAME || 'GRH'
    }
  },
  created() {
    this.$store.commit('OPEN_TAB', this.steps[0].slug)
  },
  mounted() {
    setTimeout(() => {
      const element = document.querySelector('.step-cards')
      if (element) {
        const { top, bottom } = element.getBoundingClientRect()
        this.$store.commit('SET_SCROLLTOP', top)
      }
    }, 750)
  },
})
