




















import Vue from 'vue'
import { mapGetters } from 'vuex'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

export default Vue.extend({
  components: {
    MoonLoader,
  },
  props: {
    color: {
      type: String,
      default: 'lightgrey',
    },
  },
  computed: {
    ...mapGetters(['uiText']),
    isStart(): boolean {
      return !this.$store.state.active && this.$store.state.texts && this.$store.state.texts.ui
    },
    isFetching(): boolean {
      return this.$store.state.uiState === 'fetching'
    },
    alt(): string {
      const file = this.$store.state.logoUrl.split('/').pop()
      return file
        .replace('.png', '')
        .replace('-', ' ')
        .toUpperCase()
    },
  },
})
