import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import router from './router'
import store from './store'

declare global {
  interface Window {
    // tslint:disable-next-line no-any
    dataLayer: any
  }
}

window.dataLayer = window.dataLayer || []

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    this.$store.commit('INIT_STORE')
    this.$store.commit('HTTP_ERROR', null)
  },
}).$mount('#app')
