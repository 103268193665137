








































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Theme } from '@typesCustom/index'

export default Vue.extend({
  computed: {
    ...mapGetters(['uiText']),
    buttonLabel(): string {
      const { next } = this.$store.getters
      if (next === 'results') {
        return this.$store.getters.uiText('verzend')
      }
      if (next) {
        const { label } = this.$store.state.themes.find((theme: Theme) => theme.id === next)
        return `${this.$store.getters.uiText('volgende')}: ${label}`
      }
      return '[ ERROR ]'
    },
  },
  methods: {
    async submit() {
      const { answers, next } = this.$store.getters
      if (next === 'results') {
        await this.$store.dispatch('getResults', answers)
      } else {
        await this.$store.dispatch('postAnswers', answers)
      }
      this.$router.push(next)
    },
    async getResults() {
      const { answers } = this.$store.getters
      await this.$store.dispatch('getResults', answers)
      this.$router.push('results')
    },
    startAgain() {
      this.$store.dispatch('startAgain')
      this.$router.push({ name: 'home' })
    },
  },
})
