

















import Vue from 'vue'
import { RouteConfig } from 'vue-router/types'
import { AddressLookup, Theme } from '@typesCustom/index'

import FormPage from './views/FormPage.vue'
import LoadingSpinner from './components/LoadingSpinner.vue'
import Logo from '@/components/Logo.vue'

export default Vue.extend({
  components: { LoadingSpinner, Logo  },
  data: () => ({
    color: process.env.VUE_APP_COLOR,
    noTooltips: false,
  }),

  mounted() {
    window.addEventListener('keydown', key => {
      const { keyCode, code } = key
      if (keyCode === 27 || code === 'Escape') {
        this.noTooltips = true
      }
      window.setTimeout(() => {
        this.noTooltips = false
      }, 5000)
    })

    window.addEventListener('mousemove', () => {
      this.noTooltips = false
    })
  },

  /**
   * Load all form and UI data from API and  Contentful CMS
   * Dynamically create all form page routes
   */
  async created() {
    if (!this.$store.state.cached) {
      this.$store.commit('UI_STATE', 'loading')
      await Promise.all([this.$store.dispatch('loadTexts'), this.$store.dispatch('loadThemes')])
      this.$store.commit('UI_STATE', 'resting')
    }

    if (window.location.search) {
      const id: string = getUrlParameter('address')
      if (id) {
        await this.$store.dispatch('getAddress', id)
        if (this.$store.state.httpResponse) {
          return
        }
      }
    }

    const routes: RouteConfig[] = this.$store.state.themes.map((theme: Theme) => {
      const name = theme.id
      const path = name !== 'home' ? `/${name}` : '/'
      return { path, name, component: FormPage }
    })
    this.$router.addRoutes(routes)
  },
})

function getUrlParameter(name: string): string {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}
