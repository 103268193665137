import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import cache from '../lib/local-storage'
import { MutationTree } from 'vuex/types'
import { QuestionContent } from '@typesCustom/contentful'
import {
  Address,
  Answer,
  EnergyComparisonResult,
  HouseScanResult,
  HouseScanSolutionResult,
  HttpResponse,
  Question,
  QuestionObject,
  RootState,
  Theme,
} from '@typesCustom/index'

const storeKey: string = process.env.VUE_APP_STORE_KEY || ''

const mutations: MutationTree<RootState> = {
  /**
   * Initialize store, from local storage if available
   */
  INIT_STORE(state: RootState) {
    state.cached = false
    if (cache.get(storeKey)) {
      // Replace the state object with the stored item
      Object.assign(state, cache.get(storeKey))
    }
    state.uuid = uuidv4()
    state.solution = null
    setTimeout(() => {
      window.dataLayer.push({ event: 'customEvent', uuid: state.uuid, step: 1 })
    }, 1000)
  },

  /**
   * Store UI texts
   */
  LOAD_TEXTS(state: RootState, texts: QuestionObject) {
    Object.assign(state, { texts })
  },

  /**
   * Load the themes in store with default answer and question arrays
   */
  LOAD_THEMES(state: RootState, data: Theme[]) {
    const themes = data.map(
      (x: Theme): Theme => {
        const { id, label, position } = x
        return { id, label, position, answers: [], questions: [] }
      },
    )
    Object.assign(state, { themes, cached: true, init: true })
  },

  /**
   * Set active theme
   */
  SET_ACTIVE(state: RootState, active: string) {
    if (state.active === active) {
      return
    }

    if (state.active && !state.answered.includes(state.active)) {
      state.answered = [...state.answered, state.active]
    }

    if (active === 'home' && state.verified) {
      window.dataLayer.push({ event: 'customEvent', uuid: state.uuid, step: 3 })
    }

    Object.assign(state, { active })

    setTimeout(() => {
      window.dataLayer.push({ event: 'pageView', uuid: state.uuid, page: window.location.pathname })
    }, 0)
  },

  /**
   * Store looked up address from API
   */
  SET_ADDRESS(state: RootState, address: Address) {
    Object.assign(state, { address })
    window.dataLayer.push({ event: 'customEvent', uuid: state.uuid, step: 2 })
  },

  /**
   * Clear user address from store
   */
  CLEAR_ADDRESS(state: RootState) {
    Object.assign(state, { address: {} })
  },

  /**
   * Clear all answers from store
   */
  CLEAR_ANSWERS(state: RootState) {
    state.themes.forEach((theme: Theme) => {
      theme.answers = []
    })
    Object.assign(state, { comparison: {} })
    state.verified = false
  },

  /**
   * Load set of questions for active theme
   */
  SET_QUESTIONS(state: RootState, questions: Question[]) {
    const theme = state.themes.find((x: Theme) => x.id === state.active)
    Object.assign(theme, { questions })
  },

  /**
   * Store energy comparison results
   */
  ENERGY_COMPARISON(state: RootState, comparison: EnergyComparisonResult) {
    Object.assign(state, { comparison })
  },

  /**
   * Save answer in store: append if not present yet.
   */
  SAVE_ANSWER(state: RootState, answer: Answer) {
    const theme = state.themes.find((x: Theme) => x.id === state.active)
    let answers = theme && theme.answers ? theme.answers : []
    const index = answers.findIndex((x: Answer) => x.id === answer.id)
    if (index > -1) {
      answers.splice(index, 1, answer)
    } else {
      answers = [...answers, answer]
    }

    Object.assign(theme, { answers })
  },

  /**
   * Delete answer from store.
   */
  DELETE_ANSWER(state: RootState, answer: Answer) {
    const theme = state.themes.find((x: Theme) => x.id === state.active)
    const answers = theme && theme.answers ? theme.answers : []
    const index = answers.findIndex((x: Answer) => x.id === answer.id)
    if (index > -1) {
      answers.splice(index, 1)
    }

    Object.assign(theme, { answers })
  },

  /**
   * Set address state to verified by user
   */
  CONFIRM_ADDRESS(state: RootState) {
    Object.assign(state, { verified: true })
    window.dataLayer.push({ event: 'customEvent', uuid: state.uuid, step: 3 })
  },

  /**
   * Register a required question on component mount
   */
  REQUIRE(state: RootState, questionId: string) {
    if (!state.required.includes(questionId)) {
      state.required = [...state.required, questionId]
    }
  },

  /**
   * Un-register a required question on component destroy
   */
  UN_REQUIRE(state: RootState, questionId: string) {
    state.required = state.required.filter(x => x !== questionId)
  },

  /**
   * Don't actually clear the cache, just don't use it anymore :-)
   */
  CLEAR_CACHE(state: RootState) {
    state.cached = false
  },

  /**
   * Store the returned HouseScan solution response and reset state
   */
  SET_RESULTS(state: RootState, solution: HouseScanSolutionResult) {
    state.solution = { ...solution }
  },

  /**
   * Store HTTP error status and text for display
   */
  HTTP_ERROR(state: RootState, httpResponse: HttpResponse | null) {
    httpResponse ? Object.assign(state, { httpResponse }) : delete state.httpResponse
  },

  /**
   * Change the current UI status
   */
  UI_STATE(state: RootState, uiState: string) {
    Object.assign(state, { uiState })
  },

  /**
   * Store user selected solution (override house scan result)
   */
  UPDATE_SOLUTION(state: RootState, { selected, solutionId }: { selected: string; solutionId: string }) {
    const solution = state.solution!.results.find((r: HouseScanResult) => r.id === solutionId)
    if (solution) {
      Vue.set(solution, 'selected', selected)
    }
  },

  /**
   * Dynamically update Contentful UI texts
   */
  UPDATE_UI_TEXT(state: RootState, { key, question }: { key: string; question: QuestionContent }) {
    const { questions } = state.texts
    questions[key] = question
    Vue.set(state.texts, 'questions', questions)
  },

  /**
   * Set URL for logo in open data loading screen
   */
  SET_LOGO(state: RootState, logoUrl: string) {
    state.logoUrl = logoUrl
  },

  /**
   * Show clicked tab in results page
   */
  OPEN_TAB(state: RootState, tab: string) {
    state.activeTab = tab
    if (state.scrollTop) {
      window.scrollTo({ top: state.scrollTop, behavior: 'smooth' })
    }
  },

  /**
   * Scroll offset for result page steps
   */
  SET_SCROLLTOP(state: RootState, scrollTop: number) {
    state.scrollTop = scrollTop
  },
}

export default mutations
