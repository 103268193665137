
















import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: mapGetters(['uiText']),
  data() {
    return {
      tips: ['investering', 'besparing', 'roi'],
    }
  },
})
