import Vue from 'vue'
import Vuex from 'vuex'
import cache from '../lib/local-storage'
import { Store, MutationPayload } from 'vuex/types'
import { RootState } from '@typesCustom/index'

import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

Vue.use(Vuex)

const store: Store<RootState> = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state,
  actions,
  mutations,
  getters,
})

// Cache state in local storage on each mutation
if (process.env.NODE_ENV === 'production') {
  // tslint:disable-next-line:no-shadowed-variable
  store.subscribe((mutation: MutationPayload, state: RootState) => {
    cache.set(process.env.VUE_APP_STORE_KEY!, state)
  })
}

export default store
