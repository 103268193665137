




import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapGetters(['uiText']),
    privacy(): string {
      const text = this.uiText('privacy-statement')
      if (text !== 'privacy-statement') {
        const link = this.uiText('privacy-statement-link')
        return text.replace(/\[(\w+)\]/i, `<a href="${link}" target="_blank">$1</a>`)
      }
      return ''
    },
  },
})
