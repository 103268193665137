



































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapGetters(['uiText']),
    isModal(): boolean {
      return window.location !== window.parent.location
    },
  },
  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
    close() {
      window.parent.postMessage('close form', '*')
      window.close()
    },
    startAgain() {
      this.$store.dispatch('startAgain')
      this.$router.push({ name: 'home' })
    },
  },
})
