

















import Vue from 'vue'
import { Theme } from '@typesCustom/index'

export default Vue.extend({
  computed: {
    answered(): string[] {
      return this.$store.state.answered
    },
    active(): string {
      const step = this.steps.find(x => x.key === this.$store.state.active)
      return step ? step.label : ''
    },
    steps(): Array<{ key: string; label: string }> {
      const themes = this.$store.state.themes.map((t: Theme) => ({ key: t.id, label: t.label }))
      return [...themes, { key: 'results', label: this.$store.getters.uiText('results-label') }]
    },
    index(): number {
      return this.steps.findIndex(x => x.key === this.$store.state.active) || 0
    },
    width(): string {
      const percentage = (this.index / (this.steps.length - 1)) * 100
      const pixels = this.index ? 14 - this.index * 3 : 0
      return `width: calc(${percentage}% + ${pixels}px)`
    },
  },
  methods: {
    goTo({ key }: { key: string }) {
      this.$router.push({ name: key })
    },
    labelClass(i: number): string {
      let classes = 'current'
      if (this.index !== i) {
        classes += ' current-hidden'
      }
      if (this.index === 0) {
        classes += ' current-first'
      }
      if (this.index === this.steps.length - 1) {
        classes += ' current-last'
      }
      return classes
    },
  },
})
