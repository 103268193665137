





















import { Asset } from 'contentful'
import Vue, { PropType } from 'vue'
import { SolutionStep } from '@typesCustom/contentful'

export default Vue.extend({
  props: {
    step: {
      type: Object as PropType<SolutionStep>,
      required: true,
    },
  },
  computed: {
    icon(): string {
      return this.step.icon.fields.file.url
    },
    active(): boolean {
      return this.step.slug === this.$store.state.activeTab
    },
  },
  methods: {
    openTab() {
      this.$store.commit('OPEN_TAB', this.step.slug)
    },
  },
})
