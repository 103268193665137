
















import { Entry } from 'contentful'
import Vue, { PropType } from 'vue'
import LegendHeader from './LegendHeader.vue'
import EnergySavingMeasure from './EnergySavingMeasure.vue'
import { SolutionContent, SolutionStep } from '@typesCustom/contentful'

export default Vue.extend({
  name: 'saving-step',
  components: {
    LegendHeader,
    'saving-measure': EnergySavingMeasure,
  },
  props: {
    step: {
      type: Object as PropType<SolutionStep>,
      required: true,
    },
  },
  computed: {
    title(): string {
      return this.step.name
    },
    description(): string {
      return this.step.omschrijving || ''
    },
    measures(): Array<Entry<SolutionContent>> {
      return this.step.maatregelen
    },
    icon(): string {
      return this.step.icon.fields.file.url
    },
  },
})
