




















import Vue, { PropType } from 'vue'
import * as contentful from 'contentful'
import { Address, Label } from '@typesCustom/index'
import { Asset, CreateClientParams, ContentfulClientApi, EntryCollection } from 'contentful'

const config: CreateClientParams = {
  space: process.env.VUE_APP_TVW_SPACE || '',
  accessToken: process.env.VUE_APP_TVW_TOKEN || '',
  environment: process.env.VUE_APP_TVW_ENV || 'master',
}

const api: ContentfulClientApi = contentful.createClient({ ...config })

interface TvwSolution {
  readonly slug?: string
  readonly constructionYear?: string
  readonly solution?: string
  readonly displaySolution: string
}

interface TvwMunicipality {
  readonly online: boolean
  readonly slug: string
  readonly municipalityID: string
  readonly logo: Asset
}

export default Vue.extend({
  props: {
    address: {
      type: Object as PropType<Address>,
      required: true,
    },
  },
  data() {
    return {
      fields: null as TvwSolution | null,
      municipality: null as TvwMunicipality | null,
    }
  },
  computed: {
    buildPeriod(): string {
      if (!this.address || !this.address.verblijfsobject) {
        return ''
      }
      const buildYear = Number(this.address.verblijfsobject.year)

      switch (true) {
        case buildYear < 1930:
          return 'voor 1930'
        case buildYear <= 1965:
          return '1930 - 1965'
        case buildYear <= 1975:
          return '1966 - 1975'
        case buildYear <= 1987:
          return '1976 - 1987'
        case buildYear <= 1992:
          return '1988 -  1992'
        case buildYear <= 1999:
          return '1993 - 1999'
        case buildYear <= 2012:
          return '2000 - 2012'
        case buildYear >= 2013:
          return '2013 of later'
        default:
          return ''
      }
    },
    tvwUrl(): string {
      if (!this.address) {
        return ''
      }
      const { addition, houseNumber, zipCode } = this.address
      const municipality = this.address.municipality as Label
      return `${process.env.VUE_APP_TVW_URL}/${this.municipality?.slug}?postcode=${zipCode}&huisnummer=${houseNumber}&toevoeging=${addition}`
    },
    logo(): string {
      return this.municipality?.logo?.fields?.file?.url || ''
    },
  },
  async created() {
    if (!this.address || !this.address.neighbourhood) {
      return
    }

    // 1. Check if this municipality has a TWV
    const municipality = this.address.municipality as Label
    try {
      // tslint:disable-next-line
      const response: EntryCollection<TvwMunicipality> = await api.getEntries({
        // prettier-ignore
        'content_type': 'municipality',
        'fields.municipalityID': municipality.id,
        'fields.online': true,
        select: 'fields.online,fields.slug,fields.municipalityID,fields.logo',
        limit: 1,
      })

      if (response.total && response.items[0]) {
        this.municipality = response.items[0].fields
      } else {
        return
      }
    } catch (error) {
      // tslint:disable-next-line
      console.error(error)
      return
    }

    // 2. Get simplified TVW solution
    try {
      const solution: EntryCollection<TvwSolution> = await api.getEntries({
        // prettier-ignore
        'content_type': this.municipality.municipalityID === 'GM0363' ? 'tvwSolution' : 'solution',
        'fields.neighbourhood': this.address.neighbourhood.id,
        'fields.constructionYear': this.buildPeriod,
        select: 'fields.slug,fields.constructionYear,fields.solution,fields.displaySolution',
        limit: 1,
      })

      if (solution.total && solution.items[0]) {
        this.fields = solution.items[0].fields as TvwSolution
      }
    } catch (error) {
      // tslint:disable-next-line
      console.error(error)
    }
  },
})
