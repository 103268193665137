import { CacheItem, RootState } from '@typesCustom/index'

export default {
  set(key: string | number, value: RootState, hours: number = 24) {
    const data: CacheItem = {
      expires_at: Date.now() + Math.round(hours * 60 * 60 * 1000),
      value,
    }
    localStorage.setItem(key.toString(), JSON.stringify(data))
  },

  get(key: string | number): RootState | null {
    const item: string | null = localStorage.getItem(key.toString())

    if (item) {
      const data: CacheItem = JSON.parse(item)

      if (data.expires_at !== null && data.expires_at < Date.now()) {
        localStorage.removeItem(key.toString())
      } else {
        return data.value
      }
    }
    return null
  },
}
