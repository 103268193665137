


























































import { mapGetters } from 'vuex'
import Vue, { Component, PropType } from 'vue'
import InfoBar from './InfoBar.vue'
import { Address, Answer, CollectionOptions, HttpResponse, Question } from '@typesCustom/index'

const apiKey: string = process.env.VUE_APP_MAPS_API_KEY || ''

export default Vue.extend({
  components: {
    InfoBar,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<CollectionOptions>,
      required: true,
    },
    inputTypes: {
      type: Object as PropType<{ key: Component }>,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['displayText', 'uiText']),
    address(): Address {
      return this.$store.state.address
    },
    error(): HttpResponse {
      return this.$store.state.httpResponse
    },
    questions(): Question[] {
      return Object.keys(this.options).map(e => this.options[e])
    },
    required(): string[] {
      return this.questions.reduce((acc: string[], q: Question) => {
        return q.required ? [...acc, q.id] : acc
      }, [])
    },
    disabled(): boolean {
      const ids: string[] = this.$store.getters.answers!.map((x: Answer) => x.id)
      for (const id of this.required) {
        if (!ids.includes(id)) {
          return true
        }
      }
      return false
    },
    photoUrl(): string {
      const { lat, lon } = this.address
      return `https://maps.googleapis.com/maps/api/streetview?size=200x200&location=${lat},${lon}&key=${apiKey}`
    },
  },
  methods: {
    confirm() {
      this.$store.commit('CONFIRM_ADDRESS')
    },
    imageUrlAlt(event: Event) {
      ;(event.target as HTMLImageElement).src = this.$store.state.texts.media['home-icon']
    },
  },
})
